@import "src/Shared/style.scss";
@import url("https://fonts.googleapis.com/css?family=#{$googlemainfont}&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  background: $black;

  font-family: $mainfont;
  font-size: 14px;
  color: $white;

  &:before {
    content: "";

    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;

    width: 50%;
    height: 100%;

    background: $darkner;
  }
}

.app-content {
  .app-body {
    display: flex;
  }
}

.app-title {
  padding: 30px 0;

  text-transform: uppercase;
  letter-spacing: 7px;
  font-weight: 700;
  font-size: 24px;
  font-family: $monofont;

  &:after {
    content: "";

    display: block;

    width: 100px;
    height: 5px;
    margin-top: 10px;

    background: $white;
  }
}

.app-text {
  padding-bottom: 30px;

  p {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 160%;

    &:not(:first-of-type) {
      padding-top: 15px;
    }
  }
}

@media (max-width: 1000px) {
  body {
    background: $darkner;

    &:before {
      display: none;
    }
  }

  .app-title {
    letter-spacing: 3px;
    font-size: 20px;
  }

  .app-text {
    p {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }

  .app-content {
    .app-body {
      flex-direction: column-reverse;

      padding: 0;
    }
  }
}
